import "./App.css";
import { Route, Link, Routes } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Page1 from "./Components/page1";
function App() {
  return (
    <div className="App">
      <header></header>
      <Router>
        <Routes>
          <Route exact path="/" element={<h1>Home Page</h1>} />
          <Route exact path="page1" element={<Page1 />} />
        </Routes>
        <div className="list test no-printme">
          <Link to="/">Home</Link>
          <Link to="page1">Billing</Link>
        </div>
      </Router>
    </div>
  );
}
export default App;
