import { useState } from "react";
import "../../src/App.css";
import { Row, Col } from "react-bootstrap";

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${months[month]} ${date},${year}`;
}
function Page1() {
  const [inputFields, setInputFields] = useState([{ name: "", age: "" }]);
  const [biller, setBiller] = useState([
    { billername: "", address: "", gst: "", pincode: "" },
  ]);
  const [invoice, setInvoice] = useState("");
  const [total, setTotal] = useState("");
  const [SGST, setSGST] = useState("");
  const [CGST, setCGST] = useState("");
  const [subtotal, setSubTotal] = useState("");
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    data[index]["value"] = data[index]["quantiy"] * data[index]["price"];
    setInputFields(data);
    updateTotal();
  };
  const handleBillerChange = (event) => {
    let data = [...biller];
    data[event.target.name] = event.target.value;
    setBiller(data);
  };
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
    updateTotal();
  };
  const handleinvoice = (event) => {
    setInvoice(event.target.value);
  };
  const print = () => {
    window.print();
  };
  const updateTotal = () => {
    var subtotal = 0;
    inputFields.forEach(function (item) {
      //iterate on something
      if (item.value !== NaN) {
        subtotal = subtotal + item.value;
      }
      console.log(subtotal);
    });
    if (subtotal > 0) {
      var percent = ((subtotal / 100) * 9).toFixed(2);
      percent = parseFloat(percent);
      setCGST(percent);
      setSGST(percent);
      setSubTotal(subtotal);
      setTotal(percent + percent + subtotal);
    } else {
      setCGST(0);
      setSGST(0);
      setSubTotal(0);
      setTotal(0);
    }
  };
  const addFields = () => {
    let newfield = { name: "", age: "" };

    setInputFields([...inputFields, newfield]);
  };
  return (
    <div className="Page1" style={{ padding: "0 5% 0 5%" }}>
      <div>
        <div className="innvoice " style={{ padding: "0 0 5% 0" }}>
          <div className="row">
            <div className="text-start col-lg-6 col-md-6 col-sm-6">
              <h4>Chennai Pet Bottle</h4>
              <span>154/6, Erumaiyur, ERUMAIYUR MAIN ROAD, </span>
              <br />
              <span>Kancheepuram, Tamil Nadu, 600132</span>
              <br />
              <span>GSTIN :33AFDPJ7701H2ZR</span>
            </div>
            <div className="text-end col-lg-6 col-md-6 col-sm-6 col-sm-6">
              <Row>
                <Col
                  className="text-end"
                  style={{ "padding-right": "11%", "font-size": 22 }}
                >
                  <p className="">INVOICE</p>
                </Col>
              </Row>
              <Row>
                <Col className="display-in">
                  <div className="d-inline">
                    <h5
                      style={{
                        display: "inline-block",
                        marginRight: 10,
                        marginLeft: -30,
                      }}
                    >
                      Date :
                    </h5>
                    <input
                      name="name"
                      className="input-invoice"
                      placeholder="Invoive Number"
                      value={getDate()}
                      onChange={(event) => handleinvoice(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="display-in">
                  <div className="d-inline">
                    <h5 style={{ display: "inline-block", marginRight: 10 }}>
                      INVOICE #
                    </h5>
                    <input
                      name="name"
                      className="input-invoice"
                      placeholder="Invoive Number"
                      value={invoice}
                      onChange={(event) => handleinvoice(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col fluid>
                  <div className="d-inline"></div>
                </Col>
              </Row>
            </div>
            <div className="text-start col-lg-6 col-md-6 col-sm-6">
              <h4>BILL TO:</h4>
              <input
                name="billername"
                className="input-invoice "
                placeholder="Name"
                value={biller.billername}
                onChange={(event) => handleBillerChange(event)}
              />
              <br />
              <input
                name="address"
                className="input-invoice "
                placeholder="Address"
                value={biller.address}
                onChange={(event) => handleBillerChange(event)}
              />
              <br />
              <input
                name="pincode"
                className="input-invoice "
                placeholder="City"
                value={biller.pincode}
                // onChange={(event) => handleBillerChange(event)}
              />
              <br />
              <input
                name="gst"
                className="input-invoice "
                placeholder="GST"
                value={biller.gst}
                onChange={(event) => handleBillerChange(event)}
              />
              <br />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6"></div>
          </div>
        </div>
        <div className="text-start">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Product</th>
                <th style={{ paddingLeft: "8%" }}>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((input, index) => {
                return (
                  <tr
                    className="text-start"
                    key={index}
                    id={index}
                    style={{ paddingTop: "3%" }}
                  >
                    <td>
                      <select
                        name="name"
                        className="input-invoice"
                        placeholder="Product"
                        value={input.name}
                        id="dropdown"
                        onChange={(event) => handleFormChange(index, event)}
                      >
                        <option value="grapefruit">300 ml Bottle</option>
                        <option value="lime">500 ml Bottle</option>
                        <option value="coconut">1 liter Bottle</option>
                      </select>
                    </td>
                    <td style={{ paddingLeft: "8%" }}>
                      <input
                        name="quantiy"
                        className="input-invoice "
                        placeholder="Quantity"
                        value={input.quantiy}
                        onChange={(event) => handleFormChange(index, event)}
                      />
                    </td>
                    <td>
                      <input
                        name="price"
                        className="input-invoice "
                        placeholder="Price"
                        value={input.price}
                        onChange={(event) => handleFormChange(index, event)}
                      />
                    </td>
                    <td>
                      <input
                        name="value"
                        className="input-invoice "
                        placeholder="Value"
                        value={input.value}
                        onChange={(event) => handleFormChange(index, event)}
                      />
                    </td>
                    <td>
                      <button
                        style={{ padding: "2%" }}
                        className="input-invoice btn-circle  btn-danger no-printme"
                        onClick={() => removeFields(index)}
                      >
                        <i class="glyphicon glyphicon-remove"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <button
                    className="input-invoice btn-circle  btn-success no-printme"
                    onClick={addFields}
                  >
                    <i class="glyphicon glyphicon-plus"></i>
                  </button>
                </td>
                <td></td>

                <td>
                  <p>SUBTOTAL</p>
                </td>
                <td>{subtotal}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>

                <td>
                  <p>CGST 9 % </p>
                </td>
                <td>{CGST}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>

                <td>
                  <p>SGST 9 %</p>
                </td>
                <td>{SGST}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <p>TOTAL</p>
                </td>
                <td>{total}</td>
                <td>
                  <span className="print no-printme" onClick={print}>
                    PRINT
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Page1;
